<template>
  <v-container class="pa-0 full-width full-height">
    <div class="background full-height full-width">
      <v-row justify="center">
        <v-card class="transparent-card card mt-16" outlined>
          <div class="text-h4 py-4 white-text">Two-Factor Authentication</div>
          <v-text-field
            label="Enter your 2FA code"
            v-model="code"
            autofocus
            dark
            rounded
            class="white--text"
            outlined
            dense
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :disabled="isButtonDisabled"
              color="pink"
              dense
              rounded
              dark
              @click="sendVerificationCode"
            >
              {{ isButtonDisabled ? "Wait..." : "Send Verification Code" }}
            </v-btn>
            <v-btn color="primary" dense rounded dark @click="verifyCode">
              Verify
            </v-btn>
          </div>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { db } from "../firebaseConfig";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";

export default {
  name: "TwoFAModal",
  props: {
    userEmail: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      code: "",
      isButtonDisabled: false
    };
  },
  mounted() {
    // Send the code when the component mounts.
    this.sendVerificationCode();
    // Warn user if they try to close the window.
    window.addEventListener("beforeunload", this.preventClose);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventClose);
  },
  methods: {
    preventClose(event) {
      event.preventDefault();
      event.returnValue = "You must enter the correct 2FA code before leaving!";
    },
    async sendVerificationCode() {
      const otp = Math.floor(100000 + Math.random() * 900000);
      try {
        // Store the OTP in Firestore for later verification.
        await setDoc(doc(db, "otp", this.userEmail), {
          code: otp,
          timestamp: new Date(),
        });

        const payload = {
          toEmail: this.userEmail,
          subject: "Your Verification Code",
          link: "https://dashboard.hodlotc.com",
          otp: otp.toString(),
        };

        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/_2FAEmail/send",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        this.$toast.success("Verification code sent to your email!");
        this.isButtonDisabled = true;
        setTimeout(() => {
          this.isButtonDisabled = false;
        }, 30000);
      } catch (error) {
        console.error("Error sending verification code:", error);
        this.$toast.error("Failed to send verification code.");
      }
    },
    async verifyCode() {
      const otpRef = doc(db, "otp", this.userEmail);
      try {
        const docSnap = await getDoc(otpRef);
        if (docSnap.exists() && docSnap.data().code.toString() === this.code) {
          // Remove the OTP doc so it can’t be reused.
          await deleteDoc(otpRef);
          // Mark TFA as verified so that your global guard stops redirecting.
          localStorage.setItem('tfaVerified', 'true');
          this.$toast.success("Verification successful!");
          window.removeEventListener("beforeunload", this.preventClose);
          // Redirect to the account page (or any route you choose)
          this.$router.push("/account");
        } else {
          this.$toast.error("Incorrect or expired code.");
        }
      } catch (error) {
        console.error("Error verifying code:", error);
        this.$toast.error("Verification failed.");
      }
    }
  }
};
</script>

<style scoped>
.card {
  margin-top: 50%;
  max-width: 600px; /* Adjust to your design */
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 10px;
}
.background {
  background-image: url("../assets/images/Login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.textfield,
.v-text-field__slot {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}
.v-label {
  color: #ccc !important;
}
.custom-btn {
  margin-top: 10px !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  background-color: transparent !important;
}
.custom-btn:hover {
  background-color: #fff !important;
  color: #000 !important;
}
.white-text {
  color: #fff !important;
}
.text-center {
  text-align: center;
}
</style>
