<template>
  <v-container class="full-width" :style="{ padding: '2% 10%' }">
    <v-row class="Auth" justify="center">
      <v-col>
        <v-row class="pointer" @click="$router.back()">
          <v-icon class="blue-text"> mdi-arrow-left </v-icon>
          <h4>Go Back</h4>
        </v-row>
        <v-row justify="center">
          <v-card
            rounded
            class="white-card full-width mt-4 pb-8"
            :style="{ padding: '2% 10%' }">
            <v-row justify="center" justify-sm="start">
              <v-col cols="12" sm="2">
                <v-row justify="center" justify-sm="start">
                  <v-icon
                    class="dark-blue-text"
                    :style="{ 'font-size': '50px' }">
                    mdi-cellphone-link
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row justify="start" justify-sm="start">
                  <h4>Two-Factor Authentication</h4>
                </v-row>
                <v-row justify="start" justify-sm="start">
                  <h5 class="grey-text">
                    Used for withdrawals and changes to security settings.
                  </h5>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-card outlined rounded :style="{ padding: '10%' }">
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-row justify="center">
                      <v-icon
                        class="blue-text"
                        :style="{ 'font-size': '50px' }">
                        mdi-at
                      </v-icon>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-row justify="center" justify-sm="start">
                      <h4>Two-Factor Authentication</h4>
                    </v-row>
                    <v-row justify="center" justify-sm="start">
                      <h5>
                        Used for withdrawals and changes to security settings.
                      </h5>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    color="pink-btn-enable"
                    rounded
                    dark
                    @click="show2FADialog = true"
                    class="ma-2">
                    Enable 2FA
                  </v-btn>
                </v-row>
              </v-card>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <!-- 2FA Confirmation Dialog -->
    <v-dialog v-model="show2FADialog" max-width="350">
      <v-card class="text-center pa-4">
        <v-card-title class="headline text-center  mr-4 ">Enable 2FA</v-card-title>
        <v-card-text>
          Enabling two-factor authentication is permanent and cannot be turned off. Are you sure you want to proceed?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn   class="small-btn" rounded @click="show2FADialog = false">No</v-btn>
          <v-btn color="pink" class="white--text small-btn" rounded @click="confirmEnable2FA">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { deleteField } from "firebase/firestore";
import { auth } from "../firebaseConfig.js";
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore, query, where, collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { useToast } from "vue-toastification";

const db = getFirestore();

export default {
  data() {
    return {
      accountData: null,
      is2FAEnabled: false,
      show2FADialog: false,
    };
  },
  methods: {
    async fetchAccountData() {
      const userEmail = auth.currentUser?.email;
      let accountCollections = ["individuals", "companies", "trusts"];
      let accountDataFound = false;

      for (let collectionName of accountCollections) {
        if (accountDataFound) break;
        const accountCollectionRef = collection(db, collectionName);
        const q = query(accountCollectionRef, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          this.accountData = querySnapshot.docs[0].data();
          this.accountDocId = querySnapshot.docs[0].id; // Save the document ID for later updates
          accountDataFound = true;
        }
      }
    },

    async confirmEnable2FA() {
      this.show2FADialog = false;
      const userEmail = auth.currentUser?.email;
      await this.update2FAState(true, userEmail);
    },

    async update2FAState(enable, userEmail) {
      const toast = useToast();
      const accountCollections = ["individuals", "companies", "trusts"];
      let updated = false;

      for (const collectionName of accountCollections) {
        if (updated) break;
        const accountCollectionRef = collection(db, collectionName);
        const q = query(accountCollectionRef, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDocRef = userDoc.ref;
          const update = { is2FAEnabled: true };
          await updateDoc(userDocRef, update);

          this.accountData.is2FAEnabled = true;
          updated = true;
          toast.success("2FA is now enabled and cannot be disabled.", { position: "top-right" });
        }
      }

      if (!updated) {
        toast.error("Failed to update 2FA status.", { position: "top-right" });
      }
    },
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.fetchAccountData();
      } else {
        this.accountData = null; // Reset accountData if not logged in
      }
    });
  },
};
</script>

<style>
.Auth .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.pink-btn-enable {
  background-color: #BE1B74 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
</style>
